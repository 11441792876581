var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" اضافة مقال "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"العنوان","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.addCourseForm.title),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "title", $$v)},expression:"addCourseForm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"عرض في","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Show In","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","options":_vm.showList,"reduce":function (val) { return val.id; }},on:{"input":_vm.getNews},model:{value:(_vm.addCourseForm.type),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "type", $$v)},expression:"addCourseForm.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"العنوان الفرعي","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"Sub Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.addCourseForm.subtitle),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "subtitle", $$v)},expression:"addCourseForm.subtitle"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"الرابط ","label-for":"blog-edit-cgory"}},[_c('validation-provider',{attrs:{"name":"YouTube Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"videor_link"},on:{"input":function($event){return _vm.removeSpace($event)}},model:{value:(_vm.addCourseForm.slug),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "slug", $$v)},expression:"addCourseForm.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.addCourseForm.type==1)?_c('p',[_vm._v("الرابط بعد الانشاء"+_vm._s(("https://api.ar.lpcentre.com/articles/" + (_vm.addCourseForm.slug))))]):_c('p',[_vm._v("الرابط بعد الانشاء"+_vm._s(("https://ar.lpcentre.com/news/" + (_vm.addCourseForm.slug))))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Tab صورة ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE2",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewE2.src = _vm.Tabimage
                            _vm.addCourseForm.tab_image=''}}},[_vm._v(" ازالة الصورة ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block  mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Tab Image ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE2",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"اختر ملف"},on:{"input":_vm.inputImageRendererTab},model:{value:(_vm.addCourseForm.tab_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "tab_image", $$v)},expression:"addCourseForm.tab_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"نص بديل للصورة","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"alt-image"},model:{value:(_vm.addCourseForm.alt_tab_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "alt_tab_image", $$v)},expression:"addCourseForm.alt_tab_image"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Header صورة ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewEl.src = _vm.Tabimage
                            _vm.ddCourseForm.header_image=''}}},[_vm._v(" ازالة الصورة ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block  mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"  ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Header Image ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"اختر ملف"},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.addCourseForm.header_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "header_image", $$v)},expression:"addCourseForm.header_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"نص بديل للصورة","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":" alt_header_image"},model:{value:(_vm.addCourseForm.alt_header_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "alt_header_image", $$v)},expression:"addCourseForm.alt_header_image"}})],1)],1)],1)],1)],1)]),(_vm.addCourseForm.type==1)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"فئة المقال ","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"type","options":_vm.categoriesList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getCoursesByCategory(_vm.addCourseForm.category)}},model:{value:(_vm.addCourseForm.category),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "category", $$v)},expression:"addCourseForm.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3086967971)})],1)],1):_vm._e(),(_vm.addCourseForm.type==1)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"الدورات المرتبطة ","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.coursesList,"multiple":"","reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                            var search = ref.search;
                            var searching = ref.searching;
                            var loading = ref.loading;
return [_vm._v(" جار التحميل ... ")]}}],null,true),model:{value:(_vm.addCourseForm.related_courses),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "related_courses", $$v)},expression:"addCourseForm.related_courses"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2043311500)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"blog-edit-course"}},[(_vm.addCourseForm.type==1)?_c('label',[_vm._v(" المقالات المرتبطة ")]):_c('label',[_vm._v(" الاخبار المرتبطة ")]),_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","options":_vm.articleByCategoryList,"reduce":function (val) { return val.id; }},scopedSlots:_vm._u([(!_vm.getCourse)?{key:"no-options",fn:function(ref){
                            var search = ref.search;
                            var searching = ref.searching;
                            var loading = ref.loading;
return [_vm._v(" اختر الفئة اولا ... ")]}}:{key:"no-options",fn:function(ref){
                            var search = ref.search;
                            var searching = ref.searching;
                            var loading = ref.loading;
return [_vm._v(" جار التحميل ... ")]}}],null,true),model:{value:(_vm.addCourseForm.related_post),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "related_post", $$v)},expression:"addCourseForm.related_post"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"التفاصيل","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Details","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'descriptionEditor'},model:{value:(_vm.addCourseForm.details),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "details", $$v)},expression:"addCourseForm.details"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[(!_vm.isLoading)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" حفظ ")]):_vm._e(),(_vm.isLoading)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":""}},[_vm._v(" جار الحفظ... "),_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }